@font-face {
  font-family: "Gotham Black";
  src: url("/assets/fonts/Gotham-Black.otf") format("otf");
  font-weight: normal;
  font-style: normal;
  font-variant: normal;
  font-display: swap;
}
@font-face {
  font-family: "Gotham Bold";
  src: url("/assets/fonts/Gotham-Bold.otf") format("otf"), url("/assets/fonts/Gotham-Bold.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-variant: normal;
  font-display: swap;
}
@font-face {
  font-family: "Gotham Medium";
  src: url("/assets/fonts/Gotham-Medium.otf") format("otf"), url("/assets/fonts/Gotham-Medium.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-variant: normal;
  font-display: swap;
}
@font-face {
  font-family: "Gotham Book";
  src: url("/assets/fonts/Gotham-Book.otf") format("otf"), url("/assets/fonts/Gotham-Book.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-variant: normal;
  font-display: swap;
}

body {
  font-family: $primary-font-book;
}

h1, h2, h3, h4, h5, h6 {
  font-family: $primary-font-medium;
}

p {
  font-family: $primary-font-book;
}

