.container {
  width: 100%;
  max-width: 1170px;
  padding-left: 25px;
  padding-right: 25px;
  margin: 0 auto;

  &.maxWidth {
    max-width: none;
    padding-left: 0;
    padding-right: 0;
  }
}

@include for-desktop {
  .container {
    max-width: 1280px;
    padding-left: 80px;
    padding-right: 80px;
  }
}