
// colors
$primary-color: #0F4D2C;
$secondary-color: #C9DE7B;

$green-medium: #146635;
$primary-btn-hover: #0F5630;
$secondary-btn-hover: #ACC157;

$body-font-color: #343741;

$error-color: #FF0000;
$error-bg-color: #F9EBEB;

// fonts
$primary-font-black: "Gotham Black", Arial, Helvetica, sans-serif;
$primary-font-bold: "Gotham Bold", Arial, Helvetica, sans-serif;
$primary-font-medium: "Gotham Medium", Arial, Helvetica, sans-serif;
$primary-font-book: "Gotham Book", Arial, Helvetica, sans-serif;