.header,
.hero,
.fym,
.fymPanel,
.partners,
.cta,
.footer {
  display: none;
}

.salesOver {
  background: #fff url(https://res.cloudinary.com/thl/image/upload/v1598754908/rvsc/gnzms/green-topography-pattern.jpg);
  background-repeat: repeat;

  .container {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .brandImg {
    width: auto;
    height: 180px;
    margin: 30px auto;
  }
}

.salesOverWrapper {
  display: inline-block;
  max-width: 700px;
  margin: 0 auto;
  font-family: $primary-font-book;
  color: #fff;
  background-color: transparent;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;

  h1 {
    color: $secondary-color;
    background-color: transparent;
    font-family: $primary-font-book;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
    display: inline;
  }

  a {
    color: $secondary-color;

    &:hover {
      color: rgba($secondary-color, 0.85);
    }
  }
}